// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '/../node_modules/slick-carousel/slick/slick.css';

// Home Top
$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: false,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 4000,
  responsive: [{
      breakpoint: 1200,
      settings: {}
    },
    {
      breakpoint: 991,
      settings: {}
    },
    {
      breakpoint: 576,
      settings: {}
    },
  ]
});

setTimeout(function() {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').removeClass('animation');
});

// Home residence
$('#slick-residence').slick({
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: false,
  fade: false,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  appendArrows: '#slick-residence-arrows',
  prevArrow: '<button class="arrow-prev sprite"></button>',
  nextArrow: '<button class="arrow-next sprite"></button>',
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
      breakpoint: 1200,
      settings: {
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]})

// Slick Higlight home
$('#slick-highlight-bien').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    //fade: true,
    arrows: true,
    autoplay: true,
    //adaptiveHeight: true,
    cssEase: 'ease-in-out',
    speed: 400,
    autoplaySpeed: 6000,
    appendArrows: '#slick-highlight-arrows',
    prevArrow: '<button class="slick-prev"><span class="sprite arrow-prev"></span><button>',
    nextArrow: '<button class="slick-next"><span class="sprite arrow-next"></span><button>',
    responsive: [
        {
            breakpoint: 1700,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
    ]
});
