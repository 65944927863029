// jQuery assets
import '/theme/javascript/partials/jquery.js';
import '/theme/javascript/partials/jquery-magnificpopup.js';
import '/theme/javascript/partials/jquery-parallax.js';
import '/theme/javascript/partials/jquery-parsleyjs.js';
import '/theme/javascript/partials/jquery-slick.js';
import '/theme/javascript/partials/jquery-waypoints.js';

// Bootstrap assets
import '/theme/javascript/partials/bootstrap.js';


// Google assets
import '/theme/javascript/partials/google-font.js';
import '/theme/javascript/partials/google-map.js';

// Other assets
import '/theme/javascript/partials/aos.js';

// Modules
import '/theme/javascript/modules/01-navigation.js';
import '/theme/javascript/modules/02-helper.js';
import '/theme/javascript/modules/03-components.js';

// SCSS
import '/theme/scss/global.scss';

