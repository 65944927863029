// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main-wp, #nav-top').toggle();
  });
}

// Navigation size
function nav_size() {
  var elm = $("#article-nav-wp").offset().top;
  var position = $(window).scrollTop();
  if (position > elm) {
    $('body').addClass('header-fixed');
    var elmHeight = $("#article-nav-wp").innerHeight();
    $('body').css('paddingTop', elmHeight)
  } else {
    $('body').removeClass('header-fixed');
    $('body').css('paddingTop', 0)
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > elm) {
      $('body').addClass('header-fixed');
      var elmHeight = $("#article-nav-wp").innerHeight();
      $('body').css('paddingTop', elmHeight)
    } else {
      $('body').removeClass('header-fixed');
      $('body').css('paddingTop', 0)
    }
  });
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

// Initialisation
nav_mobile();

scroll_top();
if($('#article-nav-wp').length) {
  nav_size();
}
