// JS dependency
var parallax = require('jquery-parallax.js');

// Initialisation
$('.parallax-wp').each(function() {
  $(this).parallax({
    positionY: 'top'
  });
});

$(window).on('resize', function() {
  $(window).trigger('resize.px.parallax');
});


function animation (){
  $(window).trigger('resize.px.parallax');
}


var intervalID = window.setInterval(animation, 1000);

function f_hackOpacity(value){
  var opacity = $('.parallax-wp, .parallax-mirror').css('opacity',value);
  var resize = $(window).trigger('resize.px.parallax');
  return opacity, resize;
}
$('#accordion .collapse').on('hide.bs.collapse show.bs.collapse', function () {
  f_hackOpacity(0);
})
$('#accordion .collapse').on('hidden.bs.collapse shown.bs.collapse', function () {
  f_hackOpacity(1);
})
