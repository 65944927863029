// JS Dependency
var parsleyjs = require('parsleyjs');
var lang = $('body').attr('data-lang');
if(lang=='fr'){
    var parsleyjs_fr = require('parsleyjs/dist/i18n/fr.js');
}

// Initialisation
$('.parsley-validate').parsley();

$('.form-contact button[type=submit]').on('click', function() {
  setTimeout(function(){
    $(window).trigger('resize.px.parallax');
  }, 400)
});
