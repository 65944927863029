var waypoints = require('waypoints/lib/jquery.waypoints.min.js');

function f_dispatch(id){
  $('#article-nav-wp .link').removeClass('active');
  $('#article-nav-wp .link[href="#' + id + '"]').addClass('active');

}

$('.waypoints-item').waypoint(function(direction) {
  if (direction == 'up') {
    var id = $(this.element).attr('id');
    f_dispatch(id);
  }
},{
  offset: -100
});

$('.waypoints-item').waypoint(function(direction) {
  if (direction == 'down') {
    var id = $(this.element).attr('id');
    f_dispatch(id);
  }
},{
  offset: 100
});
